.navColor a {
    color: #fff100 !important;
}

.colorNav {
    background-color: black;
}

.navItem {
    padding: 8px;
    cursor: pointer;
    text-decoration: none;
}