.footerSection {
    background-color: #000000;
    height: auto;
    text-align: center;


    p {
        margin: 0;
        color: #fff100;
    }
}