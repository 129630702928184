.locationSection,
.backgroundImage {
  background: url('../../../img/honeycomb-background.jpg') center center no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 30vh;
  color: #fff100;

  .locationTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30vh;
    text-shadow: 4px 4px #000000;

    .locationSize {
      font-size: 100px;
    }
  }

  .location {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;

    a {
      text-decoration: none;
      color: #fff100;
    }
  }
}

/******************************************/
// MEDIA QUERIES
/******************************************/

// mobile devices iphone 7 plus
@media (min-width: 320px) and (max-width: 480px) {
  .locationSection, .backgroundImage {
    background-attachment: scroll;

    .locationSize {
      font-size: 50px !important;
      text-decoration: underline;
    }

    .locationTitle {
      display: block;
      flex-direction: column;
      text-align: center;
      height: 100%;
    }

    .location {
      padding-top: 50px;
    }
  }
}

// ipad pro || tablet device
@media (min-width: 481px) and (max-width: 1367px) {
  .locationSection, .backgroundImage {
    background-attachment: scroll;
  }
}
