.backgroundImage {
  background: url('../../img/honeycomb-background.jpg') center center no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: calc(100vh - 55px);
}

.backgroundText {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.backgroundText hr {
  opacity: 1;
  margin-left: 50%;
  margin-right: 50%;
  width: 30%;
  margin: auto;
}

.backgroundColumns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.backgroundColumns img {
  width: 650px;
  object-fit: contain;
}

/******************************************/
// MEDIA QUERIES
/******************************************/

// mobile devices iphone 7 plus
@media (min-width: 320px) and (max-width: 480px) {
  .backgroundImage {
    background-attachment: scroll;
  }
  .backgroundColumns img {
    width: 350px;
    object-fit: contain;
    display: block;
  }
}

// ipad pro || tablet devices
@media (min-width: 481px) and (max-width: 1367px) {
  .backgroundImage {
    background-attachment: scroll;
  }
}
