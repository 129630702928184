.hoursSection {
  background-color: #000000;
  color: #fff100;

  .hoursTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-shadow: 4px 4px #000000;

    .hoursSize {
      font-size: 100px;
    }
  }
  .hours {
    margin-right: 25%;
    margin-left: 25%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .hoursSize {
    font-size: 50px !important;
    text-decoration: underline;
  }
}
